//
// variables.scss
//

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;700&display=swap");
$primary: #D03634;
$primary-light: #D03634;
$blue: #1bca1b;
// $primary: #3850d5;
$success: #007c61;
$info: #10a6ba;
$warning: #f9ad28;
$danger: #eb466d;
$orange: #d88d0d;
$purple: #9261c6;
$pink: #f12699;
$white: #ffffff;
$dark: #1e3547;
$light: #eaf4fb;
$muted: #828f99;

// stylelint-disable
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #efefef;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #89a2b5;
$gray-700: #495057;
$gray-800: #2d2d2d;
$gray-900: #1d262d;

$colors: (
  "primary": $primary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "orange": $orange,
  "dark": $dark,
  "muted": $muted,
  "purple": $purple,
  "pink": $pink,
  "white": $white,
  "light": $light,
);

// Body Background
$body-bg: #f5f5f5;
$bg-gradient: linear-gradient(to bottom, $success, $primary);
$box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.05);

// Base font
$font-size-base: 14px;

$font-family-base: "Inter", sans-serif;
$font-family-secondary: "Nunito", sans-serif;

$color_1: inherit;
$color_2: #fff;
$color_3: #ffffffbf;
$color_4: #897132;
$color_5: #00000099;
$color_6: #4b3d1b;
$color_7: #000000;
$color_8: #ffd25c;
$font-family_1: "Poppins", sans-serif;
$font-family_2: "Montserrat", sans-serif;

// $font-family_2: "Times New Roman", Times, serif;
$font-family_3: "DM Sans", sans-serif;
$background-color_1: #4b3d1b;
$background-color_2: #ffffff17;
$background-color_3: #838383;
$background-color_4: #4b3d1b12;
$background-color_5: #fff;
$background-color_6: #000000;
$background-color_7: #f2f1ef;
$background-color_8: transparent;
$border-color_1: #4b3d1b;

@import "https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,700&display=swap";
/* Header */
/* Info */
/* Usage */
/* Learn */
/* Benefits */
/* Cta */
/* Footer */
/* Responsive */

$font-family-base: $font-family_1;
$font-family-secondary: $font-family_3;
// body {
//   font-family: $font-family_1;
// }
a {
  color: $color_1;
}
.top-nav {
  font-size: 14px;
  background-color: $background-color_1;
}
header {
  color: $color_2 !important;
  background: linear-gradient(
      180deg,
      rgba(30, 30, 30, 0) 0%,
      rgba(56, 46, 21, 0.441) 82.03%
    ),
    url(../../../public/assets/images/header-bg.png);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  .nav-link {
    color: $color_2 !important;
    font-family: $font-family_2;
  }
  .navbar {
    background-color: $background-color_2 !important;
    .btn-nav {
      background-color: $background-color_3;
      font-family: $font-family_2;
      border-radius: 0;
      font-size: 14px;
    }
  }
  .h-title {
    font-weight: 700;
  }
  .subtitle {
    color: $color_3;
  }
  .text-underline {
    text-decoration: underline;
  }
  .benefits {
    .text-lg {
      font-size: 18px;
      font-weight: 500;
    }
  }
}
.inline-form {
  box-shadow: 0 0 40px #ffffff63;
  .form-control {
    border-radius: 4px 0 0 4px !important;
  }
  .btn {
    border-radius: 0px 4px 4px 0px !important;
    background-color: $background-color_3;
  }
}
.info {
  h2 {
    font-size: 46px;
  }
  h4 {
    font-size: 24px;
    color: $color_4;
  }
}
.usage {
  background-color: $background-color_4;
  font-family: $font-family_3;
  h2 {
    font-size: 24px;
    font-family: $font-family_2;
    color: $color_5;
    font-weight: 500;
  }
  h4 {
    color: $color_6;
    font-size: 20px;
    font-weight: 700;
  }
  p {
    font-size: 14px;
  }
  .border-left {
    border-color: $border-color_1 !important;
  }
}
.learn {
  color: $color_2;
  background: linear-gradient(
      180deg,
      rgba(30, 30, 30, 0) 0%,
      rgba(56, 46, 21, 0.441) 82.03%
    ),
    url(../../../public/assets/images/learn-bg.png);
  background-size: cover;
  h2 {
    font-family: $font-family_2;
    font-size: 42px;
  }
  .thumb-box {
    position: relative;
    .thumbnail {
      width: 100%;
      box-shadow: 0px 4px 41px 0px #ffffff1f;
    }
  }
  .play-line {
    height: 1px;
    background-color: $background-color_5;
  }
  .play-btn {
    position: absolute;
    left: calc(50% - 15px);
    top: calc(50% - 15px);
  }
}
.benefits {
  .b-subtitle {
    font-family: $font-family_2;
    color: $color_5;
  }
  .line {
    height: 1px;
    background-color: $background-color_6;
    width: 100px;
  }
  .support {
    font-family: $font-family_2;
    color: $color_5;
  }
  .b-title {
    font-size: 46px;
    font-family: $font-family_2;
  }
}
.browser {
  background-color: $background-color_4;
}
.cta {
  background: linear-gradient(
      180deg,
      rgba(30, 30, 30, 0) 0%,
      rgba(56, 46, 21, 0.441) 82.03%
    ),
    url(../../../public/assets/images/cta-bg.png);
  background-size: cover;
  color: $color_2;
  .cta-img {
    width: 300px; 

  }
  h2 {
    font-family: $font-family_2;
  }
}

footer {
  background-color: $background-color_7;
  .f-title {
    font-size: 19px;
    color: $color_4;
    font-family: $font-family_2;
  }
  ul {
    list-style-type: none;
    font-size: 14px;
  }
  .note {
    font-size: 14px;
  }
  .f-line {
    height: 1px;
    background-color: $background-color_6;
  }
  .sub-footer {
    a {
      font-size: 14px;
    }
  }
  .dropdown-toggle {
    background-color: $background-color_8 !important;
    border: none !important;
    color: $color_7;
    &:hover {
      background-color: $background-color_8 !important;
      border: none !important;
      color: $color_7;
    }
  }
  .social-links {
    a {
      box-shadow: 0px 14px 11px 0px #00000012;
      height: 35px;
      width: 35px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      margin: 0px 5px;
      img {
        max-height: 16px;
        max-width: 20px;
      }
    }
  }
}
.show {
  footer {
    .dropdown-toggle {
      background-color: $background-color_8 !important;
      border: none !important;
      color: $color_7;
    }
  }
}
.gold {
  color: $color_4;
}
.fw-semibold {
  font-weight: 500;
}
.yellow {
  color: $color_8;
}
@media (max-width: 576px) {
  .cta {
    h2 {
      font-size: 24px;
    }
  }
  .benefits {
    .b-title {
      font-size: 32px;
    }
    .b-subtitle {
      font-size: 0.8rem;
      margin: 0;
    }
  }
  .learn {
    background-position: center;
    border-radius: 40px 40px 0 0;
  }
  .h-icons {
    height: 24px;
  }
  header {
    .h-title {
      font-size: 32px;
    }
    .subtitle {
      font-size: 18px;
    }
  }
}
